#chakra-toast-manager-top-right {
  top: 55px !important;
}

/* if wider than 1440 */
.table-parent {
  width: calc(100vw - 212px);
}

/* if less than 1440 wide */
@media only screen and (max-width: 1440px) {
  .table-parent {
    width: calc(100vw - 8px);
  }
}

/* e.g. Settings/Account page */
.table-parent-with-sidebar {
  width: calc(100vw - 400px);
}

/* e.g. Settings/Account page */
@media only screen and (max-width: 1440px) {
  .table-parent-with-sidebar {
    width: calc(100vw - 200px);
  }
}

/* e.g. Settings/Account page */
@media only screen and (max-width: 480px) {
  .table-parent-with-sidebar {
    width: 100vw;
  }
}
